var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", [
        _c(
          "div",
          { staticClass: "d-flex align-center justify-space-between w-100" },
          [
            _c("div", { staticClass: "d-flex flex-column" }, [
              _c("span", { staticClass: "secondary--text font-weight-bold" }, [
                _vm._v("Organization users"),
              ]),
              _vm.tab === _vm.tabValues.ORGANIZATION_MEMBERS_TAB &&
              _vm.fetchingOrgUsers
                ? _c("span", { staticClass: "caption" }, [
                    _vm._v("Fetching users data ....."),
                  ])
                : _vm._e(),
              _vm.tab === _vm.tabValues.ORGANIZATION_INVITATIONS_TAB &&
              _vm.fetchingOrgInvitations
                ? _c("span", { staticClass: "caption" }, [
                    _vm._v("Fetching invitations data ....."),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-card",
            { staticClass: "mt-5 overflow-hidden" },
            [
              _c(
                "v-tabs",
                {
                  ref: "tabs",
                  attrs: { color: "secondary" },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _vm._l(_vm.tabs, function (tab) {
                    return _c(
                      "v-tab",
                      { key: tab.tabName },
                      [
                        _c(
                          "v-icon",
                          { staticClass: "mr-1", attrs: { small: "" } },
                          [_vm._v(_vm._s(tab.icon))]
                        ),
                        _vm._v(" " + _vm._s(tab.tabName) + " "),
                      ],
                      1
                    )
                  }),
                  _c("v-spacer"),
                  _vm.isOrgManager
                    ? _c(
                        "v-tab",
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-1",
                              attrs: { color: "primary", small: "" },
                            },
                            [_vm._v("add")]
                          ),
                          _c(
                            "span",
                            { staticClass: "secondary--text font-weight-bold" },
                            [_vm._v("invite")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-tabs-items",
            {
              staticClass: "mt-7",
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _vm._l(_vm.tabs, function (tab) {
                return _c(
                  "v-tab-item",
                  { key: tab.tabName },
                  [
                    _c(tab.component, {
                      tag: "component",
                      on: {
                        goToInvite: function ($event) {
                          return _vm.switchTab($event.value, "invite")
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm.isOrgManager
                ? _c(
                    "v-tab-item",
                    [_c("TheHomeOrganizationInvite", { tag: "component" })],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }